
.customer-analysis-content {
  min-height: calc(100vh - 120px);
  .header {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #343434;
    margin: 26px 0;
  }
  .customer-analysis-tab {
    min-height: calc(100vh - 246px);
    background-color: #fff;
    /deep/ .customer-analysis-el-tabs {
      min-height: calc(100vh - 246px);
      background-color: #fff;
      .el-tabs__item {
        margin-top: 10px;
        padding-left: 20px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        &.is-active {
          color: #fd4446;
        }
      }
      .el-tabs__active-bar {
        background-color: #fd4446;
      }
      .el-tabs__content {
        /*height: calc(100% - 65px);*/
        .el-tab-pane {
          min-height: calc(100vh - 275px);
        }
      }
      .el-tabs__nav-wrap::after {
        background-color: #fff;
      }
    }
  }
}
