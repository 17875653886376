
    .operation-pagination {
        padding-right: 30px;
    }
    .customerService{
        height: 100%;
        min-height: calc(100vh - 275px);
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }
    .el-breadcrumb {
        line-height: 40px;
    }
    .category-table {
        width: 100%;
        height: 1%;
        flex: 1;
        border: 1px solid #eee;
        .line {
            display: flex;
            align-items: stretch;
            border-bottom: 1px solid #eee;
            &.head {
                height: 60px;
                line-height: 40px;
                background: #F5F5F5;
                color: #13131B;
            }
            &.body {
                /*min-height: 60px;*/
                color: #343441;
            }
            .td {
                width: 17.5%;
                padding: 10px 10px;
                border-left: 1px solid #eee;
                &:first-child {
                    border-left: 0;
                }
                .down {
                    cursor: pointer;
                    color: #7D7D7D;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    margin-right: 4px;
                    font-size: 14px;
                    &.current {
                        transform: rotate(90deg);
                    }
                }
                .placeIcon {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }
                .place24 {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                }
            }
            &:hover {
                background: #f2f3fa;
            }
        }
        .one-level {
            &:last-child {
                .two-level {
                    .line:last-child {
                        /*border-bottom: 0;*/
                    }
                }
            }
        }
        .two-level {
            transition: all .3s;
        }
        .table-body {
            height: calc(100vh - 327px);
            /deep/ .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
    }
    /deep/ p {
        margin: 0;
    }
    .align-start {
        align-items: flex-start!important;
    }
    .no-data {
        text-align: center;
        line-height: 60px;
        color: #999;
    }
