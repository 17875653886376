
    .look-detail {
        color: #0200D8;
        &:hover {
            color: #5864e4;
        }
    }
    .shopDecoration {
        height: calc(100vh - 275px);
    }
    .good-cover-wrapper {
        text-align: center;
        .good-cover {
            display: flex;
            align-items: center;
            justify-content: center;
            .item {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fafafa;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .item + .item {
                margin-left: 10px;
            }
            .banner {
                /*width: 80%;*/
                width: 182.857142px;
                height: 0;
                /*padding-bottom: 26.25%;*/
                padding-bottom: 60px;
                position: relative;
                background: #fafafa;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .dianzhao {
                width: 960px;
                height: 0;
                padding-bottom: 60px;
                position: relative;
                background: #fafafa;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .class-item {
            }
        }
    }
