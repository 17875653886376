
    .look-detail {
        line-height: 40px;
        color: #409EFF;
        font-weight: 500;
        display: inline-block;
        cursor: pointer;
        &:hover {
            color: #66b1ff;
        }
        &.disabled {
            color: #aaa;
            cursor: not-allowed;
        }
    }
    .shopDecoration {
        height: calc(100vh - 275px);
    }
